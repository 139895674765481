<template>
  <body
    data-rsssl="1"
    class="home page-template-default page page-id-2 wp-embed-responsive theme-aeroland woocommerce-js desktop woocommerce page-has-animation one-page header-sticky-both wide page-has-no-sidebar wpb-js-composer js-comp-ver-6.6.0 vc_responsive window-resized loaded mobile-menu"
    data-site-width="1200px"
    data-content-width="1200"
    data-font="Rubik Helvetica, Arial, sans-serif"
    data-header-sticky-height="80"
    data-new-gr-c-s-check-loaded="14.998.0"
    data-gr-ext-installed=""
    cz-shortcut-listen="true"
  >
    <div id="page" class="site">
      <div class="content-wrapper" style="margin-bottom: 0px;">
        <header
          id="page-header"
          class="page-header header-03 header-layout-fixed light-logo-version header-sticky-dark-logo headroom headroom--not-bottom headroom--not-top headroom--pinned"
        >
          <div class="page-header-place-holder"></div>
          <div id="page-header-inner" class="page-header-inner" data-sticky="1">
            <div class="container-fluid">
              <!-- <div class="row">
                <div class="col-xs-12">
                  <div class="header-wrap">
                    <div class="branding">
                      <img src="@/assets/photo_logo.jpeg"/>
                    </div>

                    <div
                      id="page-navigation"
                      class="navigation page-navigation"
                    >
                      <nav id="menu" class="menu menu--primary">
                        <ul
                          id="menu-primary"
                          class="menu__container sm sm-simple"
                          data-smartmenus-id="1615917060547091"
                        >
                          <li
                            id="menu-item-136"
                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-136 level-1"
                          >
                            <a href="https://aeroland.thememove.com/">
                              <div class="menu-item-wrap">
                                <span class="menu-item-title">Landing</span>
                              </div>
                            </a>
                          </li>
                          <li
                            id="menu-item-137"
                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-137 level-1 current-menu-item"
                          >
                            <a href="#section-about">
                              <div class="menu-item-wrap">
                                <span class="menu-item-title">About</span>
                              </div>
                            </a>
                          </li>
                          <li
                            id="menu-item-138"
                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-138 level-1"
                          >
                            <a href="#section-platforms">
                              <div class="menu-item-wrap">
                                <span class="menu-item-title">Platforms</span>
                              </div>
                            </a>
                          </li>
                          <li
                            id="menu-item-139"
                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-139 level-1"
                          >
                            <a href="#section-team">
                              <div class="menu-item-wrap">
                                <span class="menu-item-title">Team</span>
                              </div>
                            </a>
                          </li>
                          <li
                            id="menu-item-140"
                            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-140 level-1"
                          >
                            <a href="#section-contact">
                              <div class="menu-item-wrap">
                                <span class="menu-item-title">Contact</span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>

                    <div class="header-right">
                      <div id="header-right-inner" class="header-right-inner">
                        <div class="header-links">
                          <div class="inner">
                            <a class="header-link" href="#"> Try Aeroland </a>
                          </div>
                        </div>

                        <div class="header-buttons">
                          <a
                            class="tm-button tm-button-nm header-button style-flat"
                            href="#"
                          >
                            <span class="button-text" data-text="Hire Us Now">
                              Hire Us Now
                            </span>
                          </a>
                          <a
                            class="tm-button tm-button-sm header-sticky-button style-solid"
                            href="#"
                          >
                            <span class="button-text" data-text="Hire Us Now">
                              Hire Us Now
                            </span>
                          </a>
                        </div>
                      </div>

                      <div
                        id="page-open-mobile-menu"
                        class="page-open-mobile-menu"
                      >
                        <div class="inner">
                          <div class="icon"><i></i></div>
                        </div>
                      </div>

                      <div id="header-right-more" class="header-right-more">
                        <div class="inner">
                          <div class="icon">
                            <i class="far fa-ellipsis-h-alt"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </header>
        <div id="page-content" class="page-content">
          <img
            class="light-theme branding-top"
            src="@/assets/white-light.png"
          />
          <div class="container">
            <div class="row">
              <div id="page-main-content" class="page-main-content">
                <article
                  id="post-2"
                  class="post-2 page type-page status-publish hentry post-no-thumbnail"
                >
                  <h2 class="screen-reader-text">Homepage</h2>
                  <div
                    id="tm-row-6050d291be670"
                    data-vc-full-width="true"
                    data-vc-full-width-init="true"
                    data-vc-stretch-content="true"
                    class="vc_row vc_row-outer vc_row-fluid"
                    style="position: relative; left: -125px; box-sizing: border-box; width: 970px;"
                  >
                    <div class="wavify-wrapper">
                      <svg
                        width="100%"
                        height="100%"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        class="wavify-item"
                        data-wavify-height="200"
                        data-wavify-background="#ffffff"
                        data-wavify-amplitude="100"
                        data-wavify-bones="2"
                      >
                        <title></title>
                        <defs></defs>
                        <path
                          d="M 0 266.1641 C 242.5 318.4605 242.5 318.4605 485 292.3124 C 727.5 266.1641 727.5 266.1641 970 292.3124 L 970 10873.1 L 0 10873.1 Z"
                          fill="#ffffff"
                        ></path>
                      </svg>
                    </div>
                    <div
                      id="tm-column-6050d291c318b"
                      class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-6"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            id="tm-row-inner-6050d291c9405"
                            class="vc_row vc_inner vc_row-fluid"
                          >
                            <div
                              id="tm-column-inner-6050d291ca806"
                              class="wpb_column vc_column_container vc_col-sm-12"
                            >
                              <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                  <div
                                    class="tm-spacer"
                                    id="tm-spacer-6050d291caac1"
                                  ></div>

                                  <div
                                    class="tm-heading left tm-animation move-up animate"
                                    id="tm-heading-6050d291cacc4"
                                  >
                                    <h3 class="heading" style="">
                                      Where Tech comes together to serve you.
                                    </h3>
                                  </div>

                                  <div
                                    class="tm-spacer"
                                    id="tm-spacer-6050d291cba5e"
                                  ></div>

                                  <div
                                    class="tm-heading left tm-animation move-up animate"
                                    id="tm-heading-6050d291cbbf4"
                                  >
                                    <div class="heading" style="">
                                      Save your time, money and energy with
                                      us.<br />
                                      Call Us:
                                      <a href="tel:(+91)9047873537"
                                        >(+91) 90478 73537</a
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d291cbed5"
                          ></div>
                          <div
                            class="tm-button-group"
                            id="tm-button-group-6050d291cbfe9"
                          >
                            <div
                              class="tm-button-wrapper tm-animation move-up animate"
                              id="tm-button-6050d291cf43f"
                              @click="onClickGetStarted"
                            >
                              <a
                                class="tm-button style-flat tm-button-custom tm-button-custom"
                              >
                                <span
                                  class="button-text"
                                  data-text="Get started"
                                >
                                  Get started
                                </span>
                              </a>
                            </div>

                            <!-- <div
                              class="tm-heading link-style-01 left tm-animation move-up animate"
                              id="tm-heading-6050d291cf936"
                            >
                              <h3 class="heading" style="">
                                Questions? <a href="#">Talk to our team </a>
                              </h3>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="tm-column-6050d291d166b"
                      class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-6"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d291d189f"
                          ></div>
                          <div
                            class="tm-image tm-animation move-up animate"
                            id="tm-image-6050d291d19fe"
                          >
                            <div class="image">
                              <img
                                src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2019/02/aeroland-startup-hero-image-reup.png"
                                alt="aeroland-startup-hero-image-reup"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="vc_row-full-width vc_clearfix"></div>
                  <div
                    id="section-about"
                    class="vc_row vc_row-outer vc_row-fluid"
                  >
                    <div
                      id="tm-column-6050d291d4cd5"
                      class="wpb_column vc_column_container vc_col-sm-12"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d291d4f4c"
                          ></div>

                          <div
                            class="tm-heading center tm-animation move-up animate"
                            id="tm-heading-6050d291d510b"
                          >
                            <h3 class="heading" style="">
                              We’ve created enticing designs for startups and
                              new businesses
                            </h3>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d291d5cc6"
                          ></div>
                          <!-- <div
                            class="tm-popup-video style-button-02 poster-full-wide tm-animation move-up animate"
                            id="tm-popup-video-6050d291d5de5"
                          >
                            <a
                              href="https://www.youtube.com/watch?v=9No-FiEInLA"
                              class="video-link"
                            >
                              <div class="video-content">
                                <span
                                  class="video-icon fa fa-play-circle"
                                ></span>

                                <span class="video-text">
                                  Intro &amp; demo video
                                </span>
                              </div>
                            </a>
                          </div> -->
                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d291d8ef5"
                          ></div>
                          <div
                            class="tm-grid-wrapper tm-grid-group"
                            id="tm-grid-6050d291d90ec"
                            data-item-wrap="1"
                          >
                            <div
                              class="tm-grid modern-grid has-animation move-up"
                            >
                              <div class="grid-item animate">
                                <div
                                  class="tm-box-icon style-06 tm-animation move-up animate"
                                  id="tm-box-icon-6050d291d93fd"
                                >
                                  <a href="#" class="link-secret">
                                    <div class="content-wrap">
                                      <div class="image">
                                        <img
                                          src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-startup-box-icon-01.png"
                                          alt="aeroland-startup-box-icon-01"
                                          width="64"
                                        />
                                      </div>

                                      <div class="content">
                                        <h4 class="heading">
                                          Web Development
                                        </h4>

                                        <div class="text">
                                          Our technical experts have a flair for
                                          developing clean-coded &amp; seamless
                                          websites based on customers’ needs
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div class="grid-item animate">
                                <div
                                  class="tm-box-icon style-06 tm-animation move-up animate"
                                  id="tm-box-icon-6050d291dc185"
                                >
                                  <a href="#" class="link-secret">
                                    <div class="content-wrap">
                                      <div class="image">
                                        <img
                                          src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-startup-box-icon-02.png"
                                          alt="aeroland-startup-box-icon-02"
                                          width="64"
                                        />
                                      </div>

                                      <div class="content">
                                        <h4 class="heading">
                                          Social Media
                                        </h4>

                                        <div class="text">
                                          Our presence on a wide range of social
                                          platforms leads to better marketing
                                          results &amp; larger customer base.
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div class="grid-item animate">
                                <div
                                  class="tm-box-icon style-06 tm-animation move-up animate"
                                  id="tm-box-icon-6050d291dcee9"
                                >
                                  <a href="#" class="link-secret">
                                    <div class="content-wrap">
                                      <div class="image">
                                        <img
                                          src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-startup-box-icon-03.png"
                                          alt="aeroland-startup-box-icon-03"
                                          width="64"
                                        />
                                      </div>

                                      <div class="content">
                                        <h4 class="heading">
                                          SEO Marketing
                                        </h4>

                                        <div class="text">
                                          From meticulous research on search
                                          engine optimization, we have created
                                          strategies to increase the efficiency.
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div class="grid-item animate">
                                <div
                                  class="tm-box-icon style-06 tm-animation move-up animate"
                                  id="tm-box-icon-6050d291df410"
                                >
                                  <a href="#" class="link-secret">
                                    <div class="content-wrap">
                                      <div class="image">
                                        <img
                                          src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-startup-box-icon-04.png"
                                          alt="aeroland-startup-box-icon-04"
                                          width="64"
                                        />
                                      </div>

                                      <div class="content">
                                        <h4 class="heading">
                                          Keyword Search
                                        </h4>

                                        <div class="text">
                                          Studies showed that the choice of
                                          appropriate keyword for your sites and
                                          posts helps increase site ranking.
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div class="grid-item animate">
                                <div
                                  class="tm-box-icon style-06 tm-animation move-up animate"
                                  id="tm-box-icon-6050d291e115c"
                                >
                                  <a href="#" class="link-secret">
                                    <div class="content-wrap">
                                      <div class="image">
                                        <img
                                          src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-startup-box-icon-05.png"
                                          alt="aeroland-startup-box-icon-05"
                                          width="64"
                                        />
                                      </div>

                                      <div class="content">
                                        <h4 class="heading">
                                          Email Optimization
                                        </h4>

                                        <div class="text">
                                          Keep your audiences in touch with your
                                          company’s latest news, upcoming events
                                          and new product launch via emails.
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div class="grid-item animate">
                                <div
                                  class="tm-box-icon style-06 tm-animation move-up animate"
                                  id="tm-box-icon-6050d291e3e4d"
                                >
                                  <a href="#" class="link-secret">
                                    <div class="content-wrap">
                                      <div class="image">
                                        <img
                                          src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-startup-box-icon-06.png"
                                          alt="aeroland-startup-box-icon-06"
                                          width="64"
                                        />
                                      </div>

                                      <div class="content">
                                        <h4 class="heading">
                                          Web Design, UI/UX
                                        </h4>

                                        <div class="text">
                                          We are capable of designing a whole
                                          new website for businesses and
                                          companies or even redesigning old
                                          sites.
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d291e4ee1"
                          ></div>
                          <div
                            class="tm-button-wrapper tm-animation move-up animate"
                            id="tm-button-6050d291e5024"
                          >
                            <a
                              class="tm-button style-flat tm-button-custom tm-button- has-icon icon-right"
                              href="#"
                            >
                              <span
                                class="button-text"
                                data-text="Discover how it works"
                              >
                                Discover how it works
                              </span>
                              <span class="button-icon">
                                <i class="far fa-long-arrow-right"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="section-platforms"
                    class="vc_row vc_row-outer vc_row-fluid"
                  >
                    <div
                      id="tm-column-6050d291e5640"
                      class="wpb_column vc_column_container vc_col-sm-12"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d291e583d"
                          ></div>

                          <div
                            class="tm-heading center tm-animation move-up animate"
                            id="tm-heading-6050d291e59e9"
                          >
                            <h3 class="heading" style="">
                              Present over 50 platforms
                            </h3>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d291e5c74"
                          ></div>

                          <div
                            class="tm-heading center tm-animation move-up animate"
                            id="tm-heading-6050d291e5de3"
                          >
                            <div class="heading" style="">
                              We are present over a wide range of social media
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d291e6039"
                          ></div>
                          <div
                            class="tm-grid-wrapper tm-grid-group"
                            id="tm-grid-6050d291e60e5"
                            data-item-wrap="1"
                          >
                            <div
                              class="tm-grid modern-grid has-animation move-up"
                            >
                              <div class="grid-item animate">
                                <div
                                  class="tm-box-icon style-11 tm-animation move-up animate"
                                  id="tm-box-icon-6050d291e6297"
                                >
                                  <a href="#" class="link-secret">
                                    <div class="content-wrap">
                                      <div class="icon">
                                        <span class="fab fa-react"></span>
                                      </div>

                                      <div class="content">
                                        <h4 class="heading">
                                          React Native
                                        </h4>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div class="grid-item animate">
                                <div
                                  class="tm-box-icon style-11 tm-animation move-up animate"
                                  id="tm-box-icon-6050d291e95be"
                                >
                                  <a href="#" class="link-secret">
                                    <div class="content-wrap">
                                      <div class="icon">
                                        <span class="fab fa-microsoft"></span>
                                      </div>

                                      <div class="content">
                                        <h4 class="heading">
                                          Window
                                        </h4>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div class="grid-item animate">
                                <div
                                  class="tm-box-icon style-11 tm-animation move-up animate"
                                  id="tm-box-icon-6050d291e999c"
                                >
                                  <a href="#" class="link-secret">
                                    <div class="content-wrap">
                                      <div class="icon">
                                        <span class="fab fa-apple"></span>
                                      </div>

                                      <div class="content">
                                        <h4 class="heading">
                                          MacOS
                                        </h4>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div class="grid-item animate">
                                <div
                                  class="tm-box-icon style-11 tm-animation move-up animate"
                                  id="tm-box-icon-6050d291e9d27"
                                >
                                  <a href="#" class="link-secret">
                                    <div class="content-wrap">
                                      <div class="icon">
                                        <span class="fab fa-java"></span>
                                      </div>

                                      <div class="content">
                                        <h4 class="heading">
                                          Java
                                        </h4>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div class="grid-item animate">
                                <div
                                  class="tm-box-icon style-11 tm-animation move-up animate"
                                  id="tm-box-icon-6050d291ea040"
                                >
                                  <a href="#" class="link-secret">
                                    <div class="content-wrap">
                                      <div class="icon">
                                        <span class="fab fa-android"></span>
                                      </div>

                                      <div class="content">
                                        <h4 class="heading">
                                          Android
                                        </h4>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div class="grid-item animate">
                                <div
                                  class="tm-box-icon style-11 tm-animation move-up animate"
                                  id="tm-box-icon-6050d291ee260"
                                >
                                  <a href="#" class="link-secret">
                                    <div class="content-wrap">
                                      <div class="icon">
                                        <span class="fab fa-php"></span>
                                      </div>

                                      <div class="content">
                                        <h4 class="heading">
                                          PHP
                                        </h4>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d291ee630"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    id="tm-section-6050d29200363"
                    data-vc-full-width="true"
                    data-vc-full-width-init="true"
                    class="vc_section"
                    style="position: relative; left: -125px; box-sizing: border-box; width: 970px; padding-left: 125px; padding-right: 125px;"
                  >
                    <div
                      id="tm-row-6050d29202357"
                      class="vc_row vc_row-outer vc_row-fluid vc_row-o-content-middle vc_row-flex"
                    >
                      <div
                        id="tm-column-6050d29202985"
                        class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-6"
                      >
                        <div class="vc_column-inner ">
                          <div class="wpb_wrapper">
                            <div
                              class="tm-spacer"
                              id="tm-spacer-6050d29202be9"
                            ></div>

                            <div
                              class="tm-heading left tm-animation move-up animate"
                              id="tm-heading-6050d292044ab"
                            >
                              <h3 class="heading" style="">
                                Free app download for you
                              </h3>
                            </div>

                            <div
                              class="tm-spacer"
                              id="tm-spacer-6050d29204806"
                            ></div>

                            <div
                              class="tm-heading left tm-animation move-up animate"
                              id="tm-heading-6050d292049ae"
                            >
                              <div class="heading" style="">
                                We’ll help you boost the sales and benefits
                              </div>
                            </div>

                            <div
                              class="tm-spacer"
                              id="tm-spacer-6050d29204c68"
                            ></div>
                            <div
                              class="tm-button-wrapper tm-animation move-up animate"
                              id="tm-button-6050d29206be8"
                            >
                              <a
                                class="tm-button style-flat tm-button-nm tm-button-custom has-icon icon-left"
                                href="#"
                              >
                                <span class="button-icon">
                                  <i class="fa fa-arrow-alt-to-bottom"></i>
                                </span>
                                <span
                                  class="button-text"
                                  data-text="Get AeroLand"
                                >
                                  Get AeroLand
                                </span>
                              </a>
                            </div>
                            <div
                              class="tm-spacer"
                              id="tm-spacer-6050d2920712a"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="tm-column-6050d292072be"
                        class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-6"
                      >
                        <div class="vc_column-inner ">
                          <div class="wpb_wrapper">
                            <div
                              id="tm-row-inner-6050d292075fd"
                              class="vc_row vc_inner vc_row-fluid"
                            >
                              <div
                                id="tm-column-inner-6050d29207889"
                                class="wpb_column vc_column_container vc_col-sm-12"
                              >
                                <div class="vc_column-inner ">
                                  <div class="wpb_wrapper">
                                    <div
                                      class="tm-spacer"
                                      id="tm-spacer-6050d292079d5"
                                    ></div>
                                    <div
                                      class="tm-image tm-animation move-up animate"
                                      id="tm-image-6050d29207ab9"
                                    >
                                      <div class="image">
                                        <img
                                          src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2019/01/aeroland-startup-section-download-image.png"
                                          alt="aeroland-startup-section-download-image"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="tm-row-6050d29208118"
                      class="vc_row vc_row-outer vc_row-fluid"
                    >
                      <div
                        id="tm-column-6050d2920843b"
                        class="wpb_column vc_column_container vc_col-sm-12"
                      >
                        <div class="vc_column-inner ">
                          <div class="wpb_wrapper">
                            <div
                              class="tm-spacer"
                              id="tm-spacer-6050d29208663"
                            ></div>

                            <div
                              class="tm-heading center tm-animation move-up animate"
                              id="tm-heading-6050d29208811"
                            >
                              <h3 class="heading" style="">
                                We design powerful websites for landing your
                                success.
                              </h3>
                            </div>

                            <div
                              class="tm-spacer"
                              id="tm-spacer-6050d29209fcb"
                            ></div>
                            <div
                              id="tm-tta-container-6050d2921eee9"
                              class="tm-tta-container tm-animation move-up animate"
                            >
                              <div
                                class="vc_tta-container"
                                data-vc-action="collapse"
                              >
                                <div
                                  class="vc_general vc_tta vc_tta-tabs vc_tta-color-primary vc_tta-style-aeroland-01 vc_tta-shape-rounded vc_tta-o-shape-group vc_tta-tabs-position-top vc_tta-controls-align-left"
                                >
                                  <div class="vc_tta-tabs-container">
                                    <ul class="vc_tta-tabs-list">
                                      <li class="vc_tta-tab" data-vc-tab="">
                                        <a
                                          href="#1543992672387-a208539c-d7f91545118473747"
                                          data-vc-tabs=""
                                          data-vc-container=".vc_tta"
                                          ><span class="vc_tta-title-text"
                                            >Our history</span
                                          ></a
                                        >
                                      </li>
                                      <li
                                        class="vc_tta-tab vc_active"
                                        data-vc-tab=""
                                      >
                                        <a
                                          href="#1543992672404-eea89431-15071545118473747"
                                          data-vc-tabs=""
                                          data-vc-container=".vc_tta"
                                          ><span class="vc_tta-title-text"
                                            >Our mission</span
                                          ></a
                                        >
                                      </li>
                                      <li class="vc_tta-tab" data-vc-tab="">
                                        <a
                                          href="#1543992940348-2fd0a4e2-91281545118473747"
                                          data-vc-tabs=""
                                          data-vc-container=".vc_tta"
                                          ><span class="vc_tta-title-text"
                                            >Friendly Support</span
                                          ></a
                                        >
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="vc_tta-panels-container">
                                    <div class="vc_tta-panels">
                                      <div
                                        class="vc_tta-panel"
                                        id="1543992672387-a208539c-d7f91545118473747"
                                        data-vc-content=".vc_tta-panel-body"
                                      >
                                        <div class="vc_tta-panel-heading">
                                          <h4 class="vc_tta-panel-title">
                                            <a
                                              href="#1543992672387-a208539c-d7f91545118473747"
                                              data-vc-accordion=""
                                              data-vc-container=".vc_tta-container"
                                              ><span class="vc_tta-title-text"
                                                >Our history</span
                                              ></a
                                            >
                                          </h4>
                                        </div>
                                        <div class="vc_tta-panel-body" style="">
                                          <div
                                            class="tm-spacer"
                                            id="tm-spacer-6050d2920c6fe"
                                          ></div>
                                          <div
                                            id="tm-row-inner-6050d2920c85c"
                                            class="vc_row vc_inner vc_row-fluid"
                                          >
                                            <div
                                              id="tm-column-inner-6050d2920cc1c"
                                              class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-7"
                                            >
                                              <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                  <div
                                                    class="tm-image tm-animation move-up animate"
                                                    id="tm-image-6050d2920ceba"
                                                  >
                                                    <div class="image">
                                                      <img
                                                        src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-tab-content-image-04.png"
                                                        alt="aeroland-tab-content-image-04"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="tm-spacer"
                                                    id="tm-spacer-6050d2920e913"
                                                  ></div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              id="tm-column-inner-6050d2920ea4b"
                                              class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-5"
                                            >
                                              <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                  <div
                                                    class="tm-heading left tm-animation move-up animate"
                                                    id="tm-heading-6050d2920ee67"
                                                  >
                                                    <div
                                                      class="heading"
                                                      style=""
                                                    >
                                                      Over 10 years of operating
                                                      in Marketing, we have
                                                      grown into a top-rated
                                                      company.
                                                    </div>
                                                  </div>

                                                  <div
                                                    class="tm-spacer"
                                                    id="tm-spacer-6050d2920f124"
                                                  ></div>

                                                  <div
                                                    class="tm-list style-check list-vertical tm-animation move-up animate"
                                                    id="tm-list-6050d2920f28e"
                                                  >
                                                    <div class="list-item">
                                                      <div class="list-header">
                                                        <div
                                                          class="marker"
                                                        ></div>

                                                        <div class="title-wrap">
                                                          <h6 class="title">
                                                            Design responsive
                                                            modern websites
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="list-item">
                                                      <div class="list-header">
                                                        <div
                                                          class="marker"
                                                        ></div>

                                                        <div class="title-wrap">
                                                          <h6 class="title">
                                                            Redesign or revise
                                                            design of outdated
                                                            websites
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="list-item">
                                                      <div class="list-header">
                                                        <div
                                                          class="marker"
                                                        ></div>

                                                        <div class="title-wrap">
                                                          <h6 class="title">
                                                            Develop useful
                                                            features and
                                                            functions
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="list-item">
                                                      <div class="list-header">
                                                        <div
                                                          class="marker"
                                                        ></div>

                                                        <div class="title-wrap">
                                                          <h6 class="title">
                                                            Check for code error
                                                            and bug issues
                                                          </h6>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div
                                                    class="tm-spacer"
                                                    id="tm-spacer-6050d2920f50c"
                                                  ></div>
                                                  <div
                                                    class="tm-button-wrapper tm-animation move-up animate"
                                                    id="tm-button-6050d2920f63f"
                                                  >
                                                    <a
                                                      class="tm-button style-flat tm-button-custom tm-button-"
                                                      href="#"
                                                    >
                                                      <span
                                                        class="button-text"
                                                        data-text="Learn more"
                                                      >
                                                        Learn more
                                                      </span>
                                                    </a>
                                                  </div>
                                                  <div
                                                    class="tm-spacer"
                                                    id="tm-spacer-6050d2920f6d4"
                                                  ></div>

                                                  <div
                                                    class="tm-heading link-style-01 left tm-animation move-up animate"
                                                    id="tm-heading-6050d29210566"
                                                  >
                                                    <h3
                                                      class="heading"
                                                      style=""
                                                    >
                                                      What’s next in AeroLand?
                                                      <a href="#">Learn more</a>
                                                    </h3>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="vc_tta-panel vc_active"
                                        id="1543992672404-eea89431-15071545118473747"
                                        data-vc-content=".vc_tta-panel-body"
                                      >
                                        <div class="vc_tta-panel-heading">
                                          <h4 class="vc_tta-panel-title">
                                            <a
                                              href="#1543992672404-eea89431-15071545118473747"
                                              data-vc-accordion=""
                                              data-vc-container=".vc_tta-container"
                                              ><span class="vc_tta-title-text"
                                                >Our mission</span
                                              ></a
                                            >
                                          </h4>
                                        </div>
                                        <div class="vc_tta-panel-body" style="">
                                          <div
                                            id="tm-row-inner-6050d29210da5"
                                            class="vc_row vc_inner vc_row-fluid vc_row-o-content-middle vc_row-flex"
                                          >
                                            <div
                                              id="tm-column-inner-6050d292110a8"
                                              class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-7"
                                            >
                                              <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                  <div
                                                    class="tm-image tm-animation move-up animate"
                                                    id="tm-image-6050d292115cf"
                                                  >
                                                    <div class="image">
                                                      <img
                                                        src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-tab-content-image-05.png"
                                                        alt="aeroland-tab-content-image-05"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="tm-spacer"
                                                    id="tm-spacer-6050d29212a6d"
                                                  ></div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              id="tm-column-inner-6050d29212bc4"
                                              class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-5"
                                            >
                                              <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                  <div
                                                    class="tm-heading left tm-animation move-up animate"
                                                    id="tm-heading-6050d29212ea0"
                                                  >
                                                    <h3
                                                      class="heading"
                                                      style=""
                                                    >
                                                      Learn from the<br />
                                                      best practices
                                                    </h3>
                                                  </div>

                                                  <div
                                                    class="tm-spacer"
                                                    id="tm-spacer-6050d292131d3"
                                                  ></div>

                                                  <div
                                                    class="tm-heading left tm-animation move-up animate"
                                                    id="tm-heading-6050d2921334c"
                                                  >
                                                    <div
                                                      class="heading"
                                                      style=""
                                                    >
                                                      We dream about building a
                                                      mighty empire by designing
                                                      responsive apps that
                                                      inspire business
                                                      initiatives as well as
                                                      future forwarding visions.
                                                      With simple guides,
                                                      everything is easy!
                                                    </div>
                                                  </div>

                                                  <div
                                                    class="tm-spacer"
                                                    id="tm-spacer-6050d292135c6"
                                                  ></div>
                                                  <div
                                                    class="tm-button-wrapper tm-animation move-up animate"
                                                    id="tm-button-6050d29217160"
                                                  >
                                                    <a
                                                      class="tm-button style-flat tm-button-custom tm-button-"
                                                      href="#"
                                                    >
                                                      <span
                                                        class="button-text"
                                                        data-text="Learn more"
                                                      >
                                                        Learn more
                                                      </span>
                                                    </a>
                                                  </div>
                                                  <div
                                                    class="tm-spacer"
                                                    id="tm-spacer-6050d2921728b"
                                                  ></div>

                                                  <div
                                                    class="tm-heading link-style-01 left tm-animation move-up animate"
                                                    id="tm-heading-6050d29217442"
                                                  >
                                                    <h3
                                                      class="heading"
                                                      style=""
                                                    >
                                                      What’s next in AeroLand?
                                                      <a href="#">Learn more</a>
                                                    </h3>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        class="vc_tta-panel"
                                        id="1543992940348-2fd0a4e2-91281545118473747"
                                        data-vc-content=".vc_tta-panel-body"
                                      >
                                        <div class="vc_tta-panel-heading">
                                          <h4 class="vc_tta-panel-title">
                                            <a
                                              href="#1543992940348-2fd0a4e2-91281545118473747"
                                              data-vc-accordion=""
                                              data-vc-container=".vc_tta-container"
                                              ><span class="vc_tta-title-text"
                                                >Friendly Support</span
                                              ></a
                                            >
                                          </h4>
                                        </div>
                                        <div class="vc_tta-panel-body">
                                          <div
                                            class="tm-spacer"
                                            id="tm-spacer-6050d29217a38"
                                          ></div>
                                          <div
                                            id="tm-row-inner-6050d29217b6c"
                                            class="vc_row vc_inner vc_row-fluid"
                                          >
                                            <div
                                              id="tm-column-inner-6050d29217e2d"
                                              class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-6"
                                            >
                                              <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                  <div
                                                    class="tm-image tm-animation move-up animate"
                                                    id="tm-image-6050d29217fc0"
                                                  >
                                                    <div class="image">
                                                      <img
                                                        src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-tab-content-image-06.png"
                                                        alt="aeroland-tab-content-image-06"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div
                                                    class="tm-spacer"
                                                    id="tm-spacer-6050d2921b1bc"
                                                  ></div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              id="tm-column-inner-6050d2921b434"
                                              class="wpb_column vc_column_container vc_col-sm-12 vc_col-md-6"
                                            >
                                              <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                  <div
                                                    class="tm-box-icon style-05 tm-animation move-up animate"
                                                    id="tm-box-icon-6050d2921b7fc"
                                                  >
                                                    <a
                                                      href="#"
                                                      class="link-secret"
                                                    >
                                                      <div class="content-wrap">
                                                        <div
                                                          class="content-inner"
                                                        >
                                                          <div class="image">
                                                            <img
                                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-tab-content-image-icon-01.png"
                                                              alt="aeroland-tab-content-image-icon-01"
                                                            />
                                                          </div>

                                                          <div class="content">
                                                            <h6
                                                              class="sub-heading"
                                                            >
                                                              Hotline
                                                            </h6>

                                                            <h4 class="heading">
                                                              91 9047873537
                                                            </h4>

                                                            <div class="text">
                                                              24/7 phone and
                                                              email support
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </a>
                                                  </div>
                                                  <div
                                                    class="tm-spacer"
                                                    id="tm-spacer-6050d2921e4ec"
                                                  ></div>
                                                  <div
                                                    class="tm-box-icon style-05 tm-animation move-up animate"
                                                    id="tm-box-icon-6050d2921e70e"
                                                  >
                                                    <a
                                                      href="#"
                                                      class="link-secret"
                                                    >
                                                      <div class="content-wrap">
                                                        <div
                                                          class="content-inner"
                                                        >
                                                          <div class="image">
                                                            <img
                                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-tab-content-image-icon-02.png"
                                                              alt="aeroland-tab-content-image-icon-02"
                                                            />
                                                          </div>

                                                          <div class="content">
                                                            <h6
                                                              class="sub-heading"
                                                            >
                                                              Give your Feedback
                                                            </h6>

                                                            <h4 class="heading">
                                                              gowtham@moonlightcoders.com
                                                            </h4>

                                                            <div class="text">
                                                              24/7 phone and
                                                              email support
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="tm-spacer"
                              id="tm-spacer-6050d2921f067"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="vc_row-full-width vc_clearfix"></div> -->
                  <!-- <div
                    id="section-team"
                    data-vc-full-width="true"
                    data-vc-full-width-init="true"
                    class="vc_row vc_row-outer vc_row-fluid"
                    style="position: relative; left: -125px; box-sizing: border-box; width: 970px; padding-left: 125px; padding-right: 125px;"
                  >
                    <div
                      id="tm-column-6050d2921fa76"
                      class="wpb_column vc_column_container vc_col-sm-12"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d2921fd13"
                          ></div>

                          <div
                            class="tm-heading center tm-animation move-up animate"
                            id="tm-heading-6050d29223292"
                          >
                            <h3 class="heading" style="">
                              Our experienced experts
                            </h3>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d292235de"
                          ></div>

                          <div
                            class="tm-heading center tm-animation move-up animate"
                            id="tm-heading-6050d2922375d"
                          >
                            <div class="heading" style="">
                              Cooperation unifies the best team mates.
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d292239b1"
                          ></div>
                          <div
                            class="tm-slider-group tm-swiper swiper-has-shadow pagination-style-02"
                            id="tm-slider-group-6050d29223b36"
                            data-slide-wrap="1"
                            data-xs-items="1"
                            data-sm-items="2"
                            data-md-items="3"
                            data-lg-items="4"
                            data-pagination="1"
                            data-loop="1"
                          >
                            <div class="swiper-inner">
                              <div
                                class="swiper-container swiper-container-initialized swiper-container-horizontal"
                              >
                                <div
                                  class="swiper-wrapper"
                                  style="transform: translate3d(-720px, 0px, 0px); transition-duration: 0ms;"
                                >
                                  <div
                                    class="swiper-slide swiper-slide-duplicate"
                                    style="width: 360px;"
                                    data-swiper-slide-index="2"
                                  >
                                    <div class="swiper-slide-inner">
                                      <div
                                        class="tm-team-member style-02 tm-animation move-up animate"
                                        id="tm-team-member-6050d29228493"
                                      >
                                        <div class="inner">
                                          <div class="photo">
                                            <img
                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/avatar-05-140x140.png"
                                              alt="avatar-05"
                                              width="140"
                                            />
                                          </div>

                                          <div class="info">
                                            <h3 class="name">
                                              Erik Murray
                                            </h3>

                                            <div class="position">Designer</div>

                                            <div class="social-networks">
                                              <div class="inner">
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Facebook"
                                                >
                                                  <i
                                                    class="fab fa-facebook"
                                                  ></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Twitter"
                                                >
                                                  <i class="fab fa-twitter"></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Instagram"
                                                >
                                                  <i
                                                    class="fab fa-instagram"
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="swiper-slide swiper-slide-duplicate swiper-slide-prev"
                                    style="width: 360px;"
                                    data-swiper-slide-index="3"
                                  >
                                    <div class="swiper-slide-inner">
                                      <div
                                        class="tm-team-member style-02 tm-animation move-up animate"
                                        id="tm-team-member-6050d29229fc1"
                                      >
                                        <div class="inner">
                                          <div class="photo">
                                            <img
                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/avatar-02-140x140.png"
                                              alt="avatar-02"
                                              width="140"
                                            />
                                          </div>

                                          <div class="info">
                                            <h3 class="name">
                                              Terry Myers
                                            </h3>

                                            <div class="position">Designer</div>

                                            <div class="social-networks">
                                              <div class="inner">
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Facebook"
                                                >
                                                  <i
                                                    class="fab fa-facebook"
                                                  ></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Twitter"
                                                >
                                                  <i class="fab fa-twitter"></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Instagram"
                                                >
                                                  <i
                                                    class="fab fa-instagram"
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="swiper-slide swiper-slide-active"
                                    style="width: 360px;"
                                    data-swiper-slide-index="0"
                                  >
                                    <div class="swiper-slide-inner">
                                      <div
                                        class="tm-team-member style-02 tm-animation move-up animate"
                                        id="tm-team-member-6050d29223e20"
                                      >
                                        <div class="inner">
                                          <div class="photo">
                                            <img
                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/avatar-06-140x140.png"
                                              alt="avatar-06"
                                              width="140"
                                            />
                                          </div>

                                          <div class="info">
                                            <h3 class="name">
                                              Chad Brewer
                                            </h3>

                                            <div class="position">
                                              Founder / CEO
                                            </div>

                                            <div class="social-networks">
                                              <div class="inner">
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Facebook"
                                                >
                                                  <i
                                                    class="fab fa-facebook"
                                                  ></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Twitter"
                                                >
                                                  <i class="fab fa-twitter"></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Instagram"
                                                >
                                                  <i
                                                    class="fab fa-instagram"
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="swiper-slide swiper-slide-next"
                                    style="width: 360px;"
                                    data-swiper-slide-index="1"
                                  >
                                    <div class="swiper-slide-inner">
                                      <div
                                        class="tm-team-member style-02 tm-animation move-up animate"
                                        id="tm-team-member-6050d29227603"
                                      >
                                        <div class="inner">
                                          <div class="photo">
                                            <img
                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/avatar-01-140x140.png"
                                              alt="avatar-01"
                                              width="140"
                                            />
                                          </div>

                                          <div class="info">
                                            <h3 class="name">
                                              Cynthia Casey
                                            </h3>

                                            <div class="position">Director</div>

                                            <div class="social-networks">
                                              <div class="inner">
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Facebook"
                                                >
                                                  <i
                                                    class="fab fa-facebook"
                                                  ></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Twitter"
                                                >
                                                  <i class="fab fa-twitter"></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Instagram"
                                                >
                                                  <i
                                                    class="fab fa-instagram"
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="swiper-slide"
                                    style="width: 360px;"
                                    data-swiper-slide-index="2"
                                  >
                                    <div class="swiper-slide-inner">
                                      <div
                                        class="tm-team-member style-02 tm-animation move-up animate"
                                        id="tm-team-member-6050d29228493"
                                      >
                                        <div class="inner">
                                          <div class="photo">
                                            <img
                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/avatar-05-140x140.png"
                                              alt="avatar-05"
                                              width="140"
                                            />
                                          </div>

                                          <div class="info">
                                            <h3 class="name">
                                              Erik Murray
                                            </h3>

                                            <div class="position">Designer</div>

                                            <div class="social-networks">
                                              <div class="inner">
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Facebook"
                                                >
                                                  <i
                                                    class="fab fa-facebook"
                                                  ></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Twitter"
                                                >
                                                  <i class="fab fa-twitter"></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Instagram"
                                                >
                                                  <i
                                                    class="fab fa-instagram"
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="swiper-slide swiper-slide-duplicate-prev"
                                    style="width: 360px;"
                                    data-swiper-slide-index="3"
                                  >
                                    <div class="swiper-slide-inner">
                                      <div
                                        class="tm-team-member style-02 tm-animation move-up animate"
                                        id="tm-team-member-6050d29229fc1"
                                      >
                                        <div class="inner">
                                          <div class="photo">
                                            <img
                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/avatar-02-140x140.png"
                                              alt="avatar-02"
                                              width="140"
                                            />
                                          </div>

                                          <div class="info">
                                            <h3 class="name">
                                              Terry Myers
                                            </h3>

                                            <div class="position">Designer</div>

                                            <div class="social-networks">
                                              <div class="inner">
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Facebook"
                                                >
                                                  <i
                                                    class="fab fa-facebook"
                                                  ></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Twitter"
                                                >
                                                  <i class="fab fa-twitter"></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Instagram"
                                                >
                                                  <i
                                                    class="fab fa-instagram"
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                    style="width: 360px;"
                                    data-swiper-slide-index="0"
                                  >
                                    <div class="swiper-slide-inner">
                                      <div
                                        class="tm-team-member style-02 tm-animation move-up animate"
                                        id="tm-team-member-6050d29223e20"
                                      >
                                        <div class="inner">
                                          <div class="photo">
                                            <img
                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/avatar-06-140x140.png"
                                              alt="avatar-06"
                                              width="140"
                                            />
                                          </div>

                                          <div class="info">
                                            <h3 class="name">
                                              Chad Brewer
                                            </h3>

                                            <div class="position">
                                              Founder / CEO
                                            </div>

                                            <div class="social-networks">
                                              <div class="inner">
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Facebook"
                                                >
                                                  <i
                                                    class="fab fa-facebook"
                                                  ></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Twitter"
                                                >
                                                  <i class="fab fa-twitter"></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Instagram"
                                                >
                                                  <i
                                                    class="fab fa-instagram"
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                                    style="width: 360px;"
                                    data-swiper-slide-index="1"
                                  >
                                    <div class="swiper-slide-inner">
                                      <div
                                        class="tm-team-member style-02 tm-animation move-up animate"
                                        id="tm-team-member-6050d29227603"
                                      >
                                        <div class="inner">
                                          <div class="photo">
                                            <img
                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/avatar-01-140x140.png"
                                              alt="avatar-01"
                                              width="140"
                                            />
                                          </div>

                                          <div class="info">
                                            <h3 class="name">
                                              Cynthia Casey
                                            </h3>

                                            <div class="position">Director</div>

                                            <div class="social-networks">
                                              <div class="inner">
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Facebook"
                                                >
                                                  <i
                                                    class="fab fa-facebook"
                                                  ></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Twitter"
                                                >
                                                  <i class="fab fa-twitter"></i>
                                                </a>
                                                <a
                                                  target="_blank"
                                                  href="#"
                                                  class=" hint--bounce hint--top hint--primary"
                                                  aria-label="Instagram"
                                                >
                                                  <i
                                                    class="fab fa-instagram"
                                                  ></i>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <span
                                  class="swiper-notification"
                                  aria-live="assertive"
                                  aria-atomic="true"
                                ></span>
                              </div>
                            </div>
                            <div
                              class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"
                            >
                              <span
                                class="swiper-pagination-bullet swiper-pagination-bullet-active"
                                tabindex="0"
                                role="button"
                                aria-label="Go to slide 1"
                              ></span
                              ><span
                                class="swiper-pagination-bullet"
                                tabindex="0"
                                role="button"
                                aria-label="Go to slide 2"
                              ></span
                              ><span
                                class="swiper-pagination-bullet"
                                tabindex="0"
                                role="button"
                                aria-label="Go to slide 3"
                              ></span
                              ><span
                                class="swiper-pagination-bullet"
                                tabindex="0"
                                role="button"
                                aria-label="Go to slide 4"
                              ></span>
                            </div>
                          </div>
                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d2922b13d"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="vc_row-full-width vc_clearfix"></div>
                  <div
                    id="tm-row-6050d2922b443"
                    data-vc-full-width="true"
                    data-vc-full-width-init="true"
                    class="vc_row vc_row-outer vc_row-fluid"
                    style="position: relative; left: -125px; box-sizing: border-box; width: 970px; padding-left: 125px; padding-right: 125px;"
                  >
                    <div
                      id="tm-column-6050d2922b7c0"
                      class="wpb_column vc_column_container vc_col-sm-12"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d2922b9d7"
                          ></div>

                          <div
                            class="tm-heading center tm-animation move-up animate"
                            id="tm-heading-6050d2922bb87"
                          >
                            <h3 class="heading" style="">
                              Developed for Business Growth
                            </h3>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d2922da5c"
                          ></div>

                          <div
                            class="tm-heading center tm-animation move-up animate"
                            id="tm-heading-6050d2922dc3a"
                          >
                            <div class="heading" style="">
                              Offer your business with the best assistance for
                              growth.
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d2922dee7"
                          ></div>
                          <div
                            id="tm-tta-container-6050d29231cf9"
                            class="tm-tta-container tm-animation move-up animate"
                          >
                            <div
                              class="vc_tta-container"
                              data-vc-action="collapse"
                            >
                              <div
                                class="vc_general vc_tta vc_tta-tabs vc_tta-color-primary vc_tta-style-aeroland-02 vc_tta-shape-rounded vc_tta-o-shape-group vc_tta-has-pagination vc_tta-tabs-position-top vc_tta-controls-align-left"
                              >
                                <div class="vc_tta-tabs-container">
                                  <ul class="vc_tta-tabs-list">
                                    <li
                                      class="vc_tta-tab vc_active"
                                      data-vc-tab=""
                                    >
                                      <a
                                        href="#1545200936061-4b9fa62a-2de1"
                                        data-vc-tabs=""
                                        data-vc-container=".vc_tta"
                                        ><i
                                          class="vc_tta-icon fal fa-align-left"
                                        ></i
                                        ><span class="vc_tta-title-text"
                                          ><b>Page Change Effects</b
                                          ><i
                                            >The transition between pages is
                                            made to provide convenience for
                                            users while navigating.</i
                                          ></span
                                        ></a
                                      >
                                    </li>
                                    <li class="vc_tta-tab" data-vc-tab="">
                                      <a
                                        href="#1545200936143-0894b24e-2ee1"
                                        data-vc-tabs=""
                                        data-vc-container=".vc_tta"
                                        ><i
                                          class="vc_tta-icon fal fa-rocket"
                                        ></i
                                        ><span class="vc_tta-title-text"
                                          ><b>Marketing Plans</b
                                          ><i
                                            >We are capable of sketching out
                                            competitive marketing plans that's
                                            efficiency proven.</i
                                          ></span
                                        ></a
                                      >
                                    </li>
                                    <li class="vc_tta-tab" data-vc-tab="">
                                      <a
                                        href="#1545200936229-7839ec5a-454f"
                                        data-vc-tabs=""
                                        data-vc-container=".vc_tta"
                                        ><i
                                          class="vc_tta-icon fal fa-chart-pie"
                                        ></i
                                        ><span class="vc_tta-title-text"
                                          ><b>SEO Analytic Tools</b
                                          ><i
                                            >Promote your sales and increase the
                                            ROI with the help from built-in SEO
                                            analytics tools.</i
                                          ></span
                                        ></a
                                      >
                                    </li>
                                  </ul>
                                </div>
                                <div class="vc_tta-panels-container">
                                  <div class="vc_tta-panels">
                                    <div
                                      class="vc_tta-panel vc_active"
                                      id="1545200936061-4b9fa62a-2de1"
                                      data-vc-content=".vc_tta-panel-body"
                                    >
                                      <div class="vc_tta-panel-heading">
                                        <h4 class="vc_tta-panel-title">
                                          <a
                                            href="#1545200936061-4b9fa62a-2de1"
                                            data-vc-accordion=""
                                            data-vc-container=".vc_tta-container"
                                            ><i
                                              class="vc_tta-icon fal fa-align-left"
                                            ></i
                                            ><span class="vc_tta-title-text"
                                              ><b>Page Change Effects</b
                                              ><i
                                                >The transition between pages is
                                                made to provide convenience for
                                                users while navigating.</i
                                              ></span
                                            ></a
                                          >
                                        </h4>
                                      </div>
                                      <div class="vc_tta-panel-body" style="">
                                        <div
                                          class="tm-image tm-animation move-up animate"
                                          id="tm-image-6050d2922e42f"
                                        >
                                          <div class="image">
                                            <img
                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-tab-content-image-07.jpg"
                                              alt="aeroland-tab-content-image-07"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="vc_tta-panel"
                                      id="1545200936143-0894b24e-2ee1"
                                      data-vc-content=".vc_tta-panel-body"
                                    >
                                      <div class="vc_tta-panel-heading">
                                        <h4 class="vc_tta-panel-title">
                                          <a
                                            href="#1545200936143-0894b24e-2ee1"
                                            data-vc-accordion=""
                                            data-vc-container=".vc_tta-container"
                                            ><i
                                              class="vc_tta-icon fal fa-rocket"
                                            ></i
                                            ><span class="vc_tta-title-text"
                                              ><b>Marketing Plans</b
                                              ><i
                                                >We are capable of sketching out
                                                competitive marketing plans
                                                that's efficiency proven.</i
                                              ></span
                                            ></a
                                          >
                                        </h4>
                                      </div>
                                      <div class="vc_tta-panel-body" style="">
                                        <div
                                          class="tm-image tm-animation move-up animate"
                                          id="tm-image-6050d2922ff9c"
                                        >
                                          <div class="image">
                                            <img
                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-tab-content-image-08.jpg"
                                              alt="aeroland-tab-content-image-08"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="vc_tta-panel"
                                      id="1545200936229-7839ec5a-454f"
                                      data-vc-content=".vc_tta-panel-body"
                                    >
                                      <div class="vc_tta-panel-heading">
                                        <h4 class="vc_tta-panel-title">
                                          <a
                                            href="#1545200936229-7839ec5a-454f"
                                            data-vc-accordion=""
                                            data-vc-container=".vc_tta-container"
                                            ><i
                                              class="vc_tta-icon fal fa-chart-pie"
                                            ></i
                                            ><span class="vc_tta-title-text"
                                              ><b>SEO Analytic Tools</b
                                              ><i
                                                >Promote your sales and increase
                                                the ROI with the help from
                                                built-in SEO analytics tools.</i
                                              ></span
                                            ></a
                                          >
                                        </h4>
                                      </div>
                                      <div class="vc_tta-panel-body" style="">
                                        <div
                                          class="tm-image tm-animation move-up animate"
                                          id="tm-image-6050d29231070"
                                        >
                                          <div class="image">
                                            <img
                                              src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-tab-content-image-09.jpg"
                                              alt="aeroland-tab-content-image-09"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <ul
                                    class="vc_general vc_pagination vc_pagination-style-flat vc_pagination-shape-round vc_pagination-color-white"
                                  >
                                    <li
                                      class="vc_pagination-item vc_active"
                                      data-vc-tab=""
                                    >
                                      <a
                                        href="#1545200936061-4b9fa62a-2de1"
                                        class="vc_pagination-trigger"
                                        data-vc-tabs=""
                                        data-vc-container=".vc_tta"
                                      ></a>
                                    </li>
                                    <li
                                      class="vc_pagination-item"
                                      data-vc-tab=""
                                    >
                                      <a
                                        href="#1545200936143-0894b24e-2ee1"
                                        class="vc_pagination-trigger"
                                        data-vc-tabs=""
                                        data-vc-container=".vc_tta"
                                      ></a>
                                    </li>
                                    <li
                                      class="vc_pagination-item"
                                      data-vc-tab=""
                                    >
                                      <a
                                        href="#1545200936229-7839ec5a-454f"
                                        class="vc_pagination-trigger"
                                        data-vc-tabs=""
                                        data-vc-container=".vc_tta"
                                      ></a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d29231e9e"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div
                    id="section-contact"
                    class="vc_row vc_row-outer vc_row-fluid vc_row-o-content-middle vc_row-flex"
                  >
                    <div
                      id="tm-column-6050d29242226"
                      class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-7 vc_col-md-6"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            class="tm-heading left tm-animation move-up animate"
                            id="tm-heading-6050d2924258f"
                          >
                            <h3 class="heading" style="">Be our partners!</h3>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d2924288f"
                          ></div>

                          <div
                            class="tm-heading left tm-animation move-up animate"
                            id="tm-heading-6050d29242a2a"
                          >
                            <div class="heading" style="">
                              Together we can create anything.
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d29242d05"
                          ></div>
                          <div
                            class="tm-contact-form-7 style-01 tm-animation move-up animate"
                          >
                            <div
                              role="form"
                              class="wpcf7"
                              id="wpcf7-f4-p2-o1"
                              lang="en-US"
                              dir="ltr"
                            >
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-item">
                                    <span
                                      class="wpcf7-form-control-wrap your-name"
                                      ><input
                                        type="text"
                                        name="your-name"
                                        id="name-input"
                                        value=""
                                        size="40"
                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Name *"
                                        style='background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAfBJREFUWAntVk1OwkAUZkoDKza4Utm61iP0AqyIDXahN2BjwiHYGU+gizap4QDuegWN7lyCbMSlCQjU7yO0TOlAi6GwgJc0fT/fzPfmzet0crmD7HsFBAvQbrcrw+Gw5fu+AfOYvgylJ4TwCoVCs1ardYTruqfj8fgV5OUMSVVT93VdP9dAzpVvm5wJHZFbg2LQ2pEYOlZ/oiDvwNcsFoseY4PBwMCrhaeCJyKWZU37KOJcYdi27QdhcuuBIb073BvTNL8ln4NeeR6NRi/wxZKQcGurQs5oNhqLshzVTMBewW/LMU3TTNlO0ieTiStjYhUIyi6DAp0xbEdgTt+LE0aCKQw24U4llsCs4ZRJrYopB6RwqnpA1YQ5NGFZ1YQ41Z5S8IQQdP5laEBRJcD4Vj5DEsW2gE6s6g3d/YP/g+BDnT7GNi2qCjTwGd6riBzHaaCEd3Js01vwCPIbmWBRx1nwAN/1ov+/drgFWIlfKpVukyYihtgkXNp4mABK+1GtVr+SBhJDbBIubVw+Cd/TDgKO2DPiN3YUo6y/nDCNEIsqTKH1en2tcwA9FKEItyDi3aIh8Gl1sRrVnSDzNFDJT1bAy5xpOYGn5fP5JuL95ZjMIn1ya7j5dPGfv0A5eAnpZUY3n5jXcoec5J67D9q+VuAPM47D3XaSeL4AAAAASUVORK5CYII="); background-repeat: no-repeat; background-attachment: scroll; background-size: 16px 18px; background-position: 98% 50%;'
                                    /></span>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-item">
                                    <span
                                      class="wpcf7-form-control-wrap your-email"
                                      ><input
                                        type="email"
                                        name="your-email"
                                        id="email-input"
                                        value=""
                                        size="40"
                                        class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                        aria-required="true"
                                        aria-invalid="false"
                                        placeholder="Email *"
                                    /></span>
                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-item">
                                    <span
                                      class="wpcf7-form-control-wrap your-subject"
                                      ><input
                                        type="text"
                                        name="your-subject"
                                        id="subject-input"
                                        value=""
                                        size="40"
                                        class="wpcf7-form-control wpcf7-text"
                                        aria-invalid="false"
                                        placeholder="Subject"
                                    /></span>
                                  </div>
                                  <div class="form-item">
                                    <span
                                      class="wpcf7-form-control-wrap your-message"
                                      ><textarea
                                        name="your-message"
                                        id="message-input"
                                        cols="40"
                                        rows="10"
                                        class="wpcf7-form-control wpcf7-textarea"
                                        aria-invalid="false"
                                        placeholder="Message"
                                      ></textarea
                                    ></span>
                                  </div>
                                  <div class="form-submit">
                                    <input
                                      @click="handleSendMessage"
                                      type="submit"
                                      value="Send message"
                                      class="wpcf7-form-control wpcf7-submit"
                                    /><span class="ajax-loader"></span>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="wpcf7-response-output"
                                aria-hidden="true"
                              ></div>
                            </div>
                            <div class="tm-form-loading" style="display: none;">
                              <div class="sk-wrap sk-bg-child sk-wave">
                                <div class="sk-rect sk-rect1"></div>
                                <div class="sk-rect sk-rect2"></div>
                                <div class="sk-rect sk-rect3"></div>
                                <div class="sk-rect sk-rect4"></div>
                                <div class="sk-rect sk-rect5"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="tm-column-6050d29249a94"
                      class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-5 vc_col-md-6"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            class="tm-image tm-animation move-up animate"
                            id="tm-image-6050d29249cb3"
                          >
                            <div class="image">
                              <img
                                src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/aeroland-startup-image-02.png"
                                alt="aeroland-startup-image-02"
                              />
                            </div>
                          </div>
                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d2924a10c"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="vc_row-full-width vc_clearfix"></div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.content-wrapper -->

      <div
        id="page-footer-wrapper"
        class="page-footer-wrapper footer-style-01 parallax"
      >
        <div id="page-footer" class="page-footer">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="page-footer-inner">
                  <div
                    id="tm-row-6050d29251cff"
                    data-vc-full-width="true"
                    data-vc-full-width-init="true"
                    class="vc_row vc_row-outer vc_row-fluid"
                    style="position: relative; left: -125px; box-sizing: border-box; width: 970px; padding-left: 125px; padding-right: 125px;"
                  >
                    <div
                      id="tm-column-6050d292520a8"
                      class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-3"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            class="tm-image tm-animation move-up"
                            id="tm-image-6050d292522f9"
                          >
                            <!-- <a href="https://aeroland.thememove.com/start-ups/">
                              <div class="image">
                                <img
                                  src="https://aeroland.thememove.com/start-ups/wp-content/uploads/sites/7/2018/12/light-logo.png"
                                  alt="light-logo"
                                />
                              </div>
                            </a> -->
                          </div>
                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d29253f3d"
                          ></div>
                          <div
                            class="tm-heading  left tm-animation move-up"
                            id="tm-heading-6050d2925413e"
                          >
                            <img
                              class="light-theme branding-logo-footer"
                              src="@/assets/white-light.png"
                            />
                          </div>

                          <div
                            class="tm-heading  left tm-animation move-up"
                            id="tm-heading-6050d2925413e"
                          >
                            <div class="heading" style="">
                              Chennai, India
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d2925444a"
                          ></div>

                          <div
                            class="tm-heading  left tm-animation move-up"
                            id="tm-heading-6050d292545e1"
                          >
                            <div class="heading" style="">
                              <a
                                target="_blank"
                                href="mailto:gowtham@moonlightcoders.com"
                                >gowtham@moonlightcoders.com</a
                              >
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d2925489c"
                          ></div>

                          <div
                            class="tm-heading  left tm-animation move-up"
                            id="tm-heading-6050d29254a20"
                          >
                            <div class="heading" style="">
                              <a href="tel:(+91)9047873537"
                                >(+91) 90478 73537</a
                              >
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d29255dce"
                          ></div>

                          <div
                            class="tm-heading  left tm-animation move-up"
                            id="tm-heading-6050d29255f94"
                          >
                            <div class="heading" style="">
                              <a href="https://moonlightcoders.com/"
                                >www.moonlightcoders.com</a
                              >
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d2925628b"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <!-- <div
                      id="tm-column-6050d292563f5"
                      class="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-2 vc_col-md-3"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            id="tm-widget-title-6050d29256755"
                            class="tm-widget-title widget tm-animation move-up"
                          >
                            <h2 class="widgettitle">Who we are</h2>
                          </div>

                          <div
                            class="tm-list style-normal list-vertical tm-animation move-up"
                            id="tm-list-6050d29256900"
                          >
                            <div class="list-item">
                              <div class="list-header">
                                <div class="marker"></div>

                                <div class="title-wrap">
                                  <h6 class="title">
                                    <a class="link" href="#">
                                      About us
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="list-item">
                              <div class="list-header">
                                <div class="marker"></div>

                                <div class="title-wrap">
                                  <h6 class="title">
                                    <a class="link" href="#">
                                      Before you go
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="list-item">
                              <div class="list-header">
                                <div class="marker"></div>

                                <div class="title-wrap">
                                  <h6 class="title">
                                    <a class="link" href="#">
                                      Online check in
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="list-item">
                              <div class="list-header">
                                <div class="marker"></div>

                                <div class="title-wrap">
                                  <h6 class="title">
                                    <a class="link" href="#">
                                      FAQ
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d29256be5"
                          ></div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div
                      id="tm-column-6050d29257810"
                      class="wpb_column vc_column_container vc_col-sm-6 vc_col-lg-2 vc_col-md-3"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            id="tm-widget-title-6050d292579e9"
                            class="tm-widget-title widget tm-animation move-up"
                          >
                            <h2 class="widgettitle">Quick links</h2>
                          </div>

                          <div
                            class="tm-list style-normal list-vertical tm-animation move-up"
                            id="tm-list-6050d29257ca0"
                          >
                            <div class="list-item">
                              <div class="list-header">
                                <div class="marker"></div>

                                <div class="title-wrap">
                                  <h6 class="title">
                                    <a class="link" href="#">
                                      Pick up locations
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="list-item">
                              <div class="list-header">
                                <div class="marker"></div>

                                <div class="title-wrap">
                                  <h6 class="title">
                                    <a class="link" href="#">
                                      Terms of Payment
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="list-item">
                              <div class="list-header">
                                <div class="marker"></div>

                                <div class="title-wrap">
                                  <h6 class="title">
                                    <a class="link" href="#">
                                      Privacy Policy
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="list-item">
                              <div class="list-header">
                                <div class="marker"></div>

                                <div class="title-wrap">
                                  <h6 class="title">
                                    <a class="link" href="#">
                                      Where to Find Us
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d29258068"
                          ></div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <div
                      id="tm-column-6050d2925abdc"
                      class="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-offset-1 vc_col-md-3"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            id="tm-twitter-6050d2925b185"
                            class="tm-twitter style-slider-quote-light group-style-01"
                          >
                            <div
                              class="tm-swiper"
                              data-loop="1"
                              data-lg-gutter="30"
                              data-autoplay="0"
                            >
                              <div class="swiper-inner">
                                <div
                                  class="swiper-container swiper-container-initialized swiper-container-horizontal"
                                >
                                  <div
                                    class=" swiper-wrapper"
                                    style="transition-duration: 0ms; transform: translate3d(-720px, 0px, 0px);"
                                  >
                                    <div
                                      class="swiper-slide swiper-slide-duplicate swiper-slide-prev"
                                      data-swiper-slide-index="2"
                                      style="width: 690px; margin-right: 30px;"
                                    >
                                      <div class="tweet">
                                        <div class="tweet-text">
                                          Full force into this Monday. ☕️⛷
                                          (otherwise known as: The look at that
                                          comes with falling down a 300ft hill
                                          at 70m… &nbsp;<a
                                            href="https://t.co/bHoy1PpXO5"
                                            target="_blank"
                                            >https://t.co/bHoy1PpXO5</a
                                          >&nbsp;
                                        </div>
                                        <div class="tweet-info">
                                          <h6 class="tweet-heading">
                                            Unsplash
                                          </h6>
                                          <span class="tweet-date"
                                            >March 15, 2021</span
                                          >
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="swiper-slide swiper-slide-active"
                                      data-swiper-slide-index="0"
                                      style="width: 690px; margin-right: 30px;"
                                    >
                                      <div class="tweet">
                                        <div class="tweet-text">
                                          Tending our (indoor) gardens.🪴 By
                                          &nbsp;<a
                                            href="http://twitter.com/BeazyCo"
                                            target="_blank"
                                            >BeazyCo</a
                                          >&nbsp; on Unsplash &nbsp;<a
                                            href="https://t.co/pROTKGi2PW"
                                            target="_blank"
                                            >https://t.co/pROTKGi2PW</a
                                          >&nbsp; &nbsp;<a
                                            href="https://t.co/RTuTZLPC5y"
                                            target="_blank"
                                            >https://t.co/RTuTZLPC5y</a
                                          >&nbsp;
                                        </div>
                                        <div class="tweet-info">
                                          <h6 class="tweet-heading">
                                            Unsplash
                                          </h6>
                                          <span class="tweet-date"
                                            >March 16, 2021</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="swiper-slide swiper-slide-next"
                                      data-swiper-slide-index="1"
                                      style="width: 690px; margin-right: 30px;"
                                    >
                                      <div class="tweet">
                                        <div class="tweet-text">
                                          Staying cool. 🧊 By &nbsp;<a
                                            href="http://twitter.com/ospanali"
                                            target="_blank"
                                            >ospanali</a
                                          >&nbsp; on Unsplash. &nbsp;<a
                                            href="https://t.co/eJnQGXvSw6"
                                            target="_blank"
                                            >https://t.co/eJnQGXvSw6</a
                                          >&nbsp; &nbsp;<a
                                            href="https://t.co/23cMfLWbDM"
                                            target="_blank"
                                            >https://t.co/23cMfLWbDM</a
                                          >&nbsp;
                                        </div>
                                        <div class="tweet-info">
                                          <h6 class="tweet-heading">
                                            Unsplash
                                          </h6>
                                          <span class="tweet-date"
                                            >March 15, 2021</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="swiper-slide swiper-slide-duplicate-prev"
                                      data-swiper-slide-index="2"
                                      style="width: 690px; margin-right: 30px;"
                                    >
                                      <div class="tweet">
                                        <div class="tweet-text">
                                          Full force into this Monday. ☕️⛷
                                          (otherwise known as: The look at that
                                          comes with falling down a 300ft hill
                                          at 70m… &nbsp;<a
                                            href="https://t.co/bHoy1PpXO5"
                                            target="_blank"
                                            >https://t.co/bHoy1PpXO5</a
                                          >&nbsp;
                                        </div>
                                        <div class="tweet-info">
                                          <h6 class="tweet-heading">
                                            Unsplash
                                          </h6>
                                          <span class="tweet-date"
                                            >March 15, 2021</span
                                          >
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                                      data-swiper-slide-index="0"
                                      style="width: 690px; margin-right: 30px;"
                                    >
                                      <div class="tweet">
                                        <div class="tweet-text">
                                          Tending our (indoor) gardens.🪴 By
                                          &nbsp;<a
                                            href="http://twitter.com/BeazyCo"
                                            target="_blank"
                                            >BeazyCo</a
                                          >&nbsp; on Unsplash &nbsp;<a
                                            href="https://t.co/pROTKGi2PW"
                                            target="_blank"
                                            >https://t.co/pROTKGi2PW</a
                                          >&nbsp; &nbsp;<a
                                            href="https://t.co/RTuTZLPC5y"
                                            target="_blank"
                                            >https://t.co/RTuTZLPC5y</a
                                          >&nbsp;
                                        </div>
                                        <div class="tweet-info">
                                          <h6 class="tweet-heading">
                                            Unsplash
                                          </h6>
                                          <span class="tweet-date"
                                            >March 16, 2021</span
                                          >
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <span
                                    class="swiper-notification"
                                    aria-live="assertive"
                                    aria-atomic="true"
                                  ></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d292984e3"
                          ></div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="vc_row-full-width vc_clearfix"></div>
                  <div
                    id="tm-row-6050d2929880e"
                    data-vc-full-width="true"
                    data-vc-full-width-init="true"
                    class="vc_row vc_row-outer vc_row-fluid vc_row-o-content-middle vc_row-flex"
                    style="position: relative; left: -125px; box-sizing: border-box; width: 970px; padding-left: 125px; padding-right: 125px;"
                  >
                    <div
                      id="tm-column-6050d29298b6d"
                      class="wpb_column vc_column_container vc_col-sm-6"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            class="tm-heading  left tm-animation move-up"
                            id="tm-heading-6050d29298e1f"
                          >
                            <div class="heading" style="">
                              © 2021 Moon Light Coders. All Rights Reserved.
                            </div>
                          </div>

                          <div
                            class="tm-spacer"
                            id="tm-spacer-6050d292990dc"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="tm-column-6050d29299224"
                      class="wpb_column vc_column_container vc_col-sm-6"
                    >
                      <div class="vc_column-inner ">
                        <div class="wpb_wrapper">
                          <div
                            class="tm-social-networks style-solid-rounded-icon layout-inline tm-animation move-up"
                            id="tm-social-networks-6050d292995a5"
                          >
                            <ul class="list">
                              <li class="item">
                                <a
                                  href="https://twitter.com"
                                  target="_blank"
                                  aria-label="Twitter"
                                  class="link"
                                >
                                  <i class="fab fa-twitter link-icon"></i>
                                </a>
                              </li>
                              <li class="item">
                                <a
                                  href="https://facebook.com"
                                  target="_blank"
                                  aria-label="Facebook"
                                  class="link"
                                >
                                  <i class="fab fa-facebook-f link-icon"></i>
                                </a>
                              </li>
                              <li class="item">
                                <a
                                  href="https://instagram.com"
                                  target="_blank"
                                  aria-label="Instagram"
                                  class="link"
                                >
                                  <i class="fab fa-instagram link-icon"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="vc_row-full-width vc_clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.site -->

    <!-- <div class="wooscp-popup wooscp-search">
      <div class="wooscp-popup-inner">
        <div class="wooscp-popup-content">
          <div class="wooscp-popup-content-inner">
            <div class="wooscp-popup-close"></div>
            <div class="wooscp-search-input">
              <input
                type="search"
                id="wooscp_search_input"
                placeholder="Type any keyword to search..."
              />
            </div>
            <div class="wooscp-search-result"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="wooscp-popup wooscp-settings">
      <div class="wooscp-popup-inner">
        <div class="wooscp-popup-content">
          <div class="wooscp-popup-content-inner">
            <div class="wooscp-popup-close"></div>
            Select the fields to be shown. Others will be hidden. Drag and drop
            to rearrange the order.
            <ul class="wooscp-settings-fields">
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="image"
                  checked=""
                /><span class="label">Image</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="sku"
                  checked=""
                /><span class="label">SKU</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="rating"
                  checked=""
                /><span class="label">Rating</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="price"
                  checked=""
                /><span class="label">Price</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="stock"
                  checked=""
                /><span class="label">Stock</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="availability"
                  checked=""
                /><span class="label">Availability</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="add_to_cart"
                  checked=""
                /><span class="label">Add to cart</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="description"
                  checked=""
                /><span class="label">Description</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="content"
                  checked=""
                /><span class="label">Content</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="weight"
                  checked=""
                /><span class="label">Weight</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="dimensions"
                  checked=""
                /><span class="label">Dimensions</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="additional"
                  checked=""
                /><span class="label">Additional information</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="attributes"
                  checked=""
                /><span class="label">Attributes</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="custom_attributes"
                  checked=""
                /><span class="label">Custom attributes</span>
              </li>
              <li class="wooscp-settings-field-li">
                <input
                  type="checkbox"
                  class="wooscp-settings-field"
                  value="custom_fields"
                  checked=""
                /><span class="label">Custom fields</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      id="wooscp-area"
      class="wooscp-area wooscp-bar-bottom wooscp-bar-right wooscp-hide-checkout"
      data-bg-color="#292a30"
      data-btn-color="#00a0d2"
    >
      <div class="wooscp-inner">
        <div class="wooscp-table">
          <div class="wooscp-table-inner">
            <a
              href="javascript:void(0);"
              id="wooscp-table-close"
              class="wooscp-table-close hint--left"
              aria-label="Close"
              ><span class="wooscp-table-close-icon"></span
            ></a>
            <div class="wooscp-table-items"></div>
          </div>
        </div>
        <div class="wooscp-bar ">
          <a
            href="javascript:void(0);"
            class="wooscp-bar-settings hint--top"
            aria-label="Select fields"
          ></a>
          <a
            href="javascript:void(0);"
            class="wooscp-bar-search hint--top"
            aria-label="Add product"
          ></a>
          <div class="wooscp-bar-items"></div>
          <div class="wooscp-bar-btn wooscp-bar-btn-text">
            <div class="wooscp-bar-btn-icon-wrapper">
              <div class="wooscp-bar-btn-icon-inner">
                <span></span><span></span><span></span>
              </div>
            </div>
            Compare
          </div>
        </div>
      </div>
    </div>
    <div id="woosw-area" class="woosw-area">
      <div class="woosw-inner">
        <div class="woosw-content">
          <div class="woosw-content-top">
            Wishlist <span class="woosw-count">0</span>
            <span class="woosw-close"></span>
          </div>
          <div class="woosw-content-mid"></div>
          <div class="woosw-content-bot">
            <div class="woosw-content-bot-inner">
              <span class="woosw-page">
                <a href="https://aeroland.thememove.com/start-ups/wishlist/"
                  >Open wishlist page</a
                >
              </span>
              <span class="woosw-continue" data-url="">
                Continue shopping
              </span>
            </div>
            <div class="woosw-notice"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="tm-demo-options-wrapper">
      <div class="tm-demo-options-toolbar">
        <a
          href="#"
          class="hint--bounce hint--left hint--black primary-color-hover-important"
          id="toggle-quick-options"
          aria-label="Quick Options"
        >
          <i class="fal fa-project-diagram"></i>
        </a>
        <a
          href="https://thememove.ticksy.com/"
          target="_blank"
          class="hint--bounce hint--left hint--black primary-color-hover-important"
          aria-label="Support Channel"
        >
          <i class="fal fa-life-ring"></i>
        </a>
        <a
          href="http://document.thememove.com/aeroland/"
          target="_blank"
          class="hint--bounce hint--left hint--black primary-color-hover-important"
          aria-label="Documentation"
        >
          <i class="fal fa-book"></i>
        </a>
        <a
          href="https://themeforest.net/item/aeroland-responsive-app-landing-and-website-wordpress-theme/23314522"
          target="_blank"
          class="hint--bounce hint--left hint--black primary-color-hover-important"
          aria-label="Purchase Aeroland"
        >
          <i class="fal fa-shopping-cart"></i>
        </a>
      </div>
      <div id="tm-demo-panel" class="tm-demo-panel">
        <div class="tm-demo-panel-header">
          <a
            class="tm-button style-flat tm-button-sm tm-button-primary tm-btn-purchase has-icon icon-left"
            href="https://themeforest.net/item/aeroland-responsive-app-landing-and-website-wordpress-theme/23314522"
            target="_blank"
          >
            <span class="button-icon">
              <i class="ion-android-cart"></i>
            </span>
            <span class="button-text"> Buy Aeroland Now </span>
          </a>

          <h5 class="demo-option-title">
            Aeroland – Responsive App Landing and Website WordPress Theme
          </h5>

          <div class="demo-option-desc">
            The multitude of prominents features and crucial tools for create
            responsive landing apps &amp; websites.
          </div>
        </div>

        <div class="quick-option-list">
          <a
            href="https://aeroland.thememove.com/main"
            class="hint--bounce hint--top"
            aria-label="Main"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-main.jpg"
              alt="Main"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/shop"
            class="hint--bounce hint--top"
            aria-label="Shop"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-shop.jpg"
              alt="Shop"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/branding"
            class="hint--bounce hint--top"
            aria-label="Branding"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-branding.jpg"
              alt="Branding"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/saas"
            class="hint--bounce hint--top"
            aria-label="Saas"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-saas.jpg"
              alt="Saas"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/app-showcase"
            class="hint--bounce hint--top"
            aria-label="App Showcase"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-app-showcase.jpg"
              alt="App Showcase"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/software"
            class="hint--bounce hint--top"
            aria-label="Software"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-software.jpg"
              alt="Software"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/start-ups"
            class="hint--bounce hint--top"
            aria-label="Start-ups"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-start-ups.jpg"
              alt="Start-ups"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/payment"
            class="hint--bounce hint--top"
            aria-label="Payment"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-payment.jpg"
              alt="Payment"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/app-landing"
            class="hint--bounce hint--top"
            aria-label="App Landing"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-app-landing.jpg"
              alt="App Landing"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/digital-store"
            class="hint--bounce hint--top"
            aria-label="Digital Store"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-digital-store.jpg"
              alt="Digital Store"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/digital-agency"
            class="hint--bounce hint--top"
            aria-label="Digital Agency"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-digital-agency.jpg"
              alt="Digital Agency"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/ebook"
            class="hint--bounce hint--top"
            aria-label="Ebook"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-ebook.jpg"
              alt="Ebook"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/clinic"
            class="hint--bounce hint--top"
            aria-label="Clinic"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-clinic.jpg"
              alt="Clinic"
              width="150"
              height="180"
            />
          </a>
          <a
            href="https://aeroland.thememove.com/product-showcase"
            class="hint--bounce hint--top"
            aria-label="Product Showcase"
          >
            <img
              src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland-child-demo/assets/images/home-product-showcase.jpg"
              alt="Product Showcase"
              width="150"
              height="180"
            />
          </a>
        </div>
      </div>
    </div>

    <a class="page-scroll-up show" id="page-scroll-up">
      <i class="arrow-top fal fa-long-arrow-up"></i>
      <i class="arrow-bottom fal fa-long-arrow-up"></i>
    </a>
    <div id="page-mobile-main-menu" class="page-mobile-main-menu">
      <div class="inner">
        <div class="page-mobile-menu-header">
          <div class="page-mobile-popup-logo page-mobile-menu-logo">
            <a href="https://aeroland.thememove.com/start-ups/" rel="home">
              <img
                src="https://aeroland.thememove.com/start-ups/wp-content/themes/aeroland/assets/images/dark-logo@2x.png"
                alt="Start-ups"
                width="180"
                height="34"
              />
            </a>
          </div>
          <div id="page-close-mobile-menu" class="page-close-mobile-menu"></div>
        </div>

        <div class="page-mobile-menu-content">
          <ul id="mobile-menu-primary" class="menu__container">
            <li
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-136 level-1"
            >
              <a href="https://aeroland.thememove.com/">
                <div class="menu-item-wrap">
                  <span class="menu-item-title">Landing</span>
                </div>
              </a>
            </li>
            <li
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-137 level-1"
            >
              <a href="#section-about">
                <div class="menu-item-wrap">
                  <span class="menu-item-title">About</span>
                </div>
              </a>
            </li>
            <li
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-138 level-1"
            >
              <a href="#section-platforms">
                <div class="menu-item-wrap">
                  <span class="menu-item-title">Platforms</span>
                </div>
              </a>
            </li>
            <li
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-139 level-1"
            >
              <a href="#section-team">
                <div class="menu-item-wrap">
                  <span class="menu-item-title">Team</span>
                </div>
              </a>
            </li>
            <li
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-140 level-1"
            >
              <a href="#section-contact">
                <div class="menu-item-wrap">
                  <span class="menu-item-title">Contact</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div> -->

    <div id="growls-br"></div>
  </body>
</template>

<script>
export default {
  name: "App",
  methods: {
    async handleSendMessage() {
      const nameElement = document.getElementById("name-input");
      const emailElement = document.getElementById("email-input");
      const subjectElement = document.getElementById("subject-input");
      const messageElement = document.getElementById("message-input");

      const name = nameElement.value,
        email = emailElement.value,
        subject = subjectElement.value,
        message = messageElement.value;

      if (!name || !email) return;

      await fetch("/.netlify/functions/registration", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          name,
          email,
          subject,
          message
        })
      });

      nameElement.value = "";
      emailElement.value = "";
      subjectElement.value = "";
      messageElement.value = "";
    },
    onClickGetStarted() {
      var elmnt = document.getElementById("section-contact");
      if (elmnt) elmnt.scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>

<style>
.branding-top,
.branding-logo-footer {
  height: 75px;
  user-select: none;
  z-index: 1;
}
.branding-top {
  position: absolute;
  left: 20px;
  top: 10px;
}
.branding-logo-footer {
  transform: translateY(-40px);
}
.dark-theme {
  height: 75px;
}
</style>
