import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import '@/styles/index.css';

Vue.config.productionTip = false

if(process.env == 'production') {
  Vue.use(VueGtag, {
    config: { id: "G-9W21334Y8P" }
  });
}

new Vue({
  render: h => h(App),
}).$mount('#app')
